import React from 'react';
import Dropzone from 'react-dropzone';
import { useController } from 'react-hook-form';
import {useGetIdentity} from 'react-admin';
import {upload} from '../graphqlClient';

const ImageArrayField = (props) => {
    const {source}=props;
    const fieldProps=useController({name:source}).field;
    const { identity } = useGetIdentity();
    const record={};
    record[source]=fieldProps.value;
    return (
    <div>
        <Dropzone accept="image/jpeg" maxSize={1024*1024} onDrop={(acceptedFiles)=>{
            if(acceptedFiles.length>0){
                upload(identity.accountId,acceptedFiles[0]).then(response => {
                    if(record[source]){
                        record[source].push(response.path);
                    } else {
                        record[source]=[response.path];
                    }
                    fieldProps.onChange([...record[source]]);
                }).catch(
                    error => console.log(error) // Handle the error response object
                )
            }
        }}>
            {({getRootProps, getInputProps}) => {
                return (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                )
            }}
        </Dropzone>
        {record && record[source] && record[source].map((item,idx) => (
            <img src={item} key={idx} alt={record.name} width="100"/>
        ))}
    </div>);
};
export default ImageArrayField;
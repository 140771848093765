import React from "react";
import { List, Datagrid, TextField, ReferenceField, DateField, Create, Edit, SimpleForm, ReferenceInput, SelectInput, DateInput, TextInput } from 'react-admin';
import MyProductSelector from "../components/MyProductSelector";
import MyDebugField from "../components/MyDebugField";
import {Box} from '@mui/material';
export const OrdersList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="orderRef" />
            <ReferenceField source="outlet.id" reference="Outlet">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="status" />
            <TextField source="paymentStatus" />
            <TextField source="amounts.total" />
            <DateField source="dueDate" />
        </Datagrid>
    </List>
);
export const OrdersCreate = props => {
    const transformCreateOrder = (data) => {
        const orderDetails=[];
        for(var i=0;i<data.orderDetails.length;i++){
            orderDetails.push({
                productId:data.orderDetails[i].productId,
                qty:data.orderDetails[i].qty
            });
        }
        data.dueDate=new Date(data.dueDate);
        data.orderDetails=orderDetails;
        return {...data};
    };
    return (<Create {...props} transform={transformCreateOrder}>
        <SimpleForm defaultValues={{orderDetails:[],dueDate:Date.now(),status:"Draft",remarks:"",amounts:{subtotal:0,tax:0,total:0}}}>
            <Box style={{display:"flex",width:"100%"}}>
                <Box style={{display:"flex",flexDirection:"column",width:"50%",paddingRight:8 }}>
                    <ReferenceInput source="outletId" reference="Outlet">
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
                </Box>
                <Box style={{display:"flex",flexDirection:"column", width:"50%", paddingLeft:8}}>
                    <DateInput source="dueDate"/>
                </Box>
            </Box>
            <TextInput source="remarks"/>
            <MyProductSelector source="orderDetails"/>
            <TextInput source="amounts.subtotal" disabled/>
            <TextInput source="amounts.tax" disabled/>
            <TextInput source="amounts.total" disabled/>
            <MyDebugField/>
        </SimpleForm>
    </Create>);
};
export const OrdersEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            {/*<BooleanInput source="active"/>
            <TextInput source="name" validate={[maxLength(64),required()]}/>
<ImageArrayField source="photos"/>*/}
        </SimpleForm>
    </Edit>
);
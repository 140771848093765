import React from "react";
import { Admin, Resource, CustomRoutes } from 'react-admin';
//import buildGraphQLProvider from 'ra-data-graphql-simple';
//import {authLink,httpLink,authProvider} from './graphqlClient';
import simpleRestProvider from 'ra-data-simple-rest';
import {authProvider} from './restClient';
import {SalesmenList, SalesmenCreate, SalesmenEdit} from './screens/salesmen';
import {OutletsList, OutletsEdit} from './screens/outlets';
import {CategoriesList, CategoriesCreate, CategoriesEdit} from './screens/categories';
import {ProductsList, ProductsCreate, ProductsEdit} from './screens/products';
import {UsersList, UsersCreate, UsersEdit} from './screens/users';
import {OrdersList, OrdersCreate, OrdersEdit} from './screens/orders';
import {CreatePassword} from './screens/createPassword';
import {ResetPassword} from './screens/resetPassword';
import {Route} from 'react-router-dom';
import {Login} from './screens/login'
const App = () => {
  /*const [dataProvider, setDataProvider] = React.useState(null);
  React.useEffect(() => {
      buildGraphQLProvider({ clientOptions: { link: authLink.concat(httpLink) } })
          .then(graphQlDataProvider => setDataProvider(() => graphQlDataProvider));
  }, []);

  if (!dataProvider) {
      return (<div>Loading < /div>);
  }*/
  return (<Admin dataProvider={simpleRestProvider('https://api.dms.sendidwiputra.com')} authProvider={authProvider} loginPage={<Login/>}>
    <Resource name="products" list={ProductsList} create={ProductsCreate} edit={ProductsEdit}/>
    <Resource name="categories" list={CategoriesList} create={CategoriesCreate} edit={CategoriesEdit}/>
    <Resource name="outlets" list={OutletsList} edit={OutletsEdit}/>
    <Resource name="orders" list={OrdersList} create={OrdersCreate} edit={OrdersEdit}/>
    <Resource name="users" list={UsersList} create={UsersCreate} edit={UsersEdit}/>
    <Resource name="salesmen" list={SalesmenList} create={SalesmenCreate} edit={SalesmenEdit}/>
    <CustomRoutes noLayout>
        <Route path="/ResetPassword" element={<ResetPassword/>}/>
        <Route path="/CreatePassword/:token" element={<CreatePassword/>}/>
    </CustomRoutes>
  </Admin>);
}
export default App;
import React from 'react';
import { useRecordContext } from 'react-admin';
import { useWatch,useController } from 'react-hook-form';
import {TextField} from '@mui/material';
const MyDatagridQtyInput = (props) => {
    console.log("render");
    const record = useRecordContext();
    const {source,data,datagridSource}=props;
    var idx=0;
    for(var i=0;i<data.length;i++){
        if(data[i].id===record.id){
            idx=i;
            break;
        }
    }
    const qty=useWatch({name:datagridSource+"["+idx+"]."+source});
    const field=useController({name:datagridSource}).field;
    return (<TextField value={qty} type="number" min="0" onChange={(e)=>{
        var v=0;
        if(e.target.value!==""){
            v=parseInt(e.target.value);
        }
        data[idx].qty=v;
        data[idx].subtotal=v*data[idx].discountPrice;
        field.onChange([...data]);
        props.onDataChange();
    }}/>);
}
export default MyDatagridQtyInput;
import React from 'react';
import { useRecordContext } from 'react-admin';
import { useController } from 'react-hook-form';
import {IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
const MyDatagridDeleteButton = (props) => {
    const record = useRecordContext();
    const {data,datagridSource}=props;
    const orderDetailsField=useController({name:datagridSource}).field;
    var idx=0;
    for(var i=0;i<data.length;i++){
        if(data[i].id===record.id){
            idx=i;
            break;
        }
    }
    return (<IconButton aria-label="delete" onClick={(e)=>{
        data.splice(idx,1)
        orderDetailsField.onChange([...data]);
    }}>
        <DeleteIcon />
    </IconButton>);
}
export default MyDatagridDeleteButton;
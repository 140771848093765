import React from "react";
import {List,Datagrid,TextField} from "react-admin";
import { useController,useWatch } from 'react-hook-form';
import {Button,Dialog,DialogTitle} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MyDatagridQtyInput from "./MyDatagridQtyInput";
import MyDatagridDeleteButton from "./MyDatagridDeleteButton";
const MyProductSelector = (props) => {
    const {source}=props;
    const fieldProps=useController({name:source}).field;
    const record=useWatch({name:source})||[];
    const recordAmounts=useWatch({name:"amounts"})||{};
    const amountsProps=useController({name:"amounts"}).field;
    const [open, setOpen] = React.useState(false);
    const onDataChange=()=>{
        recordAmounts.subtotal=0;
        recordAmounts.tax=0;
        recordAmounts.total=0;
        for(var i=0;i<record.length;i++){
            recordAmounts.subtotal+=record[i].subtotal;
        }
        recordAmounts.tax=recordAmounts.subtotal*0.11;
        recordAmounts.total=recordAmounts.subtotal+recordAmounts.tax;
        amountsProps.onChange({...recordAmounts});
    }
    return (<div>
        <Button variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={()=>setOpen(true)}>Add</Button>
        <Datagrid
            data={record}
            total={record.length}
            isLoading={false}
            sort={{ field: 'id', order: 'asc' }}
            bulkActionButtons={false}>
            <TextField source="name"/>
            <MyDatagridQtyInput source="qty" data={record} datagridSource={source} onDataChange={onDataChange}/>
            <TextField source="price"/>
            <TextField source="discountPrice"/>
            <TextField source="subtotal"/>
            <MyDatagridDeleteButton data={record} datagridSource={source}/>
        </Datagrid>
        <Dialog
            open={open}
            onClose={()=>setOpen(false)}>
            <DialogTitle>Select Product</DialogTitle>
            <List resource="Product" hasCreate={false} exporter={false}>
                <Datagrid bulkActionButtons={false} rowClick={(id,resource,row)=>{
                    setOpen(false);
                    var found=false;
                    for(var i=0;i<record.length;i++){
                        if(record[i].id===row.id){
                            found=true;
                            record[i].qty++;
                            record[i].price=row.price;
                            record[i].discountPrice=row.price;
                            record[i].subtotal=record[i].qty*record[i].discountPrice;
                            break;
                        }
                    }
                    if(!found){
                        record.push({
                            id:row.id,
                            productId:row.id,
                            name:row.name,
                            qty:1,
                            price:row.price,
                            discountPrice:row.price,
                            subtotal:row.price
                        });
                    }
                    fieldProps.onChange([...record]);
                    onDataChange();
                }}>
                    <TextField source="code"/>
                    <TextField source="name"/>
                    <TextField source="qty"/>
                    <TextField source="price"/>
                    <TextField source="discountPrice"/>
                </Datagrid>
            </List>
        </Dialog>
    </div>)
}
export default MyProductSelector;
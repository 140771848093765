
const server="https://api.dms.sendidwiputra.com";

export const createPassword=(token,password)=>{
    return fetch(server+'/createPassword', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token:token,
            password:password
        })
    }).then(result => {
        Promise.resolve()
    }).catch(error => Promise.reject(error));
};
export const resetPassword=(email)=>{
    return fetch(server+'/resetPassword', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email:email
        })
    }).then(result => {
        Promise.resolve()
    }).catch(error => Promise.reject(error));
};
export const authProvider = {
    login: (params) =>{
        return fetch(server+'/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email:params.username,
                password:params.password
            })
        }).then(async result => {
            var body=await result.json();
            if(result.status===200){
                localStorage.setItem("token",body);
                localStorage.setItem("profile",JSON.stringify(body));
                return Promise.resolve()
            } else {
                return Promise.reject(new Error(body.message));
            }
        }).catch(error => Promise.reject(error));
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('profile');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: params => localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        return Promise.resolve();
    },
    // get the user's profile
    getIdentity: () => {
        const profile=JSON.parse(localStorage.getItem('profile'));
        profile.fullName=profile.email;
        return Promise.resolve(profile);
    },
    // get the user permissions (optional)
    getPermissions: () => Promise.resolve(),
    // get the user roles (optional - only for Role-Based Access Control)
    getRoles: () => Promise.resolve(),
};
export const upload=(accountId,file)=>{
    var data = new FormData();
    data.append('file', file);
    data.append('accountID',accountId);
    return fetch(server+'/upload', {
        method: 'POST',
        body: data
    }).then(response=>response.json());
}